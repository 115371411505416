import * as React from "react"
import '../neat-basics/index.scss'
import "./theming.scss"
import "../styles/styles.scss"
import "./publications.scss"
import SubPageHeader from "../modules/sub-page-header"
import Page from "../modules/page"
import SectionTitle from "../elements/section-title"
import Section from "../modules/section";
import PublicationItem from "../modules/publication-item";
import Navigation from "../modules/navigation";
import ScrollNav from "../modules/scroll-nav";
import Footer from "../modules/footer";
import {graphql} from "gatsby";
import {FC} from "react";
import * as _ from "lodash";
import {
  PUBLICATION_CATEGORY_CHAPTER,
  PUBLICATION_CATEGORY_EDITED_VOLUME_OR_SPECIAL_ISSUE,
  PUBLICATION_CATEGORY_MONOGRAPH, PUBLICATION_CATEGORY_OTHER, PUBLICATION_CATEGORY_PEER_REVIEWED_ARTICLES,
  PublicationQueryResult
} from "../types";
import MobileNavigation from "../modules/mobile-navigation";
import Main from "../modules/main";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}
const Publications: FC<PublicationQueryResult>= (page: PublicationQueryResult) => {

  const publicationsByType = _.groupBy(page.data.publications.nodes, a => a.frontmatter.type);
  return (<>
    <Main title="Publications">
      <div className="publications">
      <Page>
        <SubPageHeader title="Publications">
          <Navigation activePage="publications"/>
          <MobileNavigation activePage="publications"/>
          <ScrollNav/>
        </SubPageHeader>
        {publicationsByType[PUBLICATION_CATEGORY_MONOGRAPH] &&
        <div id="monographs">
        <Section>

          <SectionTitle title="Monographs"/>
          { publicationsByType[PUBLICATION_CATEGORY_MONOGRAPH].map( p =>
            <PublicationItem showType={false} {...p}/>
          )}
          </Section>
          </div>
        }
        {publicationsByType[PUBLICATION_CATEGORY_EDITED_VOLUME_OR_SPECIAL_ISSUE] &&
        <div id="edited_volumes_and_special_issues">
        <Section>

          <SectionTitle title="Edited volumes and special issues"/>
          { publicationsByType[PUBLICATION_CATEGORY_EDITED_VOLUME_OR_SPECIAL_ISSUE].map( p =>
              <PublicationItem showType={false} {...p}/>
          )}
        </Section>
        </div>
        }
        {publicationsByType[PUBLICATION_CATEGORY_PEER_REVIEWED_ARTICLES] &&
        <div id="peer_reviewed_articles">
        <Section>

          <SectionTitle title="Peer-reviewed articles" />
          { publicationsByType[PUBLICATION_CATEGORY_PEER_REVIEWED_ARTICLES].map( p =>
              <PublicationItem showType={false} {...p}/>
          )}
        </Section>
        </div>
        }
        {publicationsByType[PUBLICATION_CATEGORY_CHAPTER] &&
        <div id="book_chapters">
        <Section>

          <SectionTitle title="book chapters" />
          { publicationsByType[PUBLICATION_CATEGORY_CHAPTER].map( p =>
              <PublicationItem showType={false} {...p}/>
          )}
        </Section>
        </div>
        }
        {publicationsByType[PUBLICATION_CATEGORY_OTHER] &&
        <div id="other_publications">
        <Section>

          <SectionTitle title="other publications"/>
          { publicationsByType[PUBLICATION_CATEGORY_OTHER].map( p =>
              <PublicationItem showType={false} {...p}/>
          )}
        </Section>
        </div>
        }
        <Footer/>
      </Page>
      </div>
    </Main></>
  )
}

export const pageQuery = graphql`
  query AllPublications {
    publications: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "publication"}}}, sort: { fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
        html
        frontmatter {
          date
          text
          url
          linkLabel
          type
        }
      }
    }
  }
`

export default Publications
