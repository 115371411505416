import * as React from "react";
import './category.scss';
import {PublicationType} from "../types";



const Category = ({ category }: {category: PublicationType}) => {
  return (
    <div className="category">
      &nbsp;•&nbsp;{category}
    </div>
  );
};

export default Category;

