import * as React from "react";
import PropTypes from "prop-types";
import './scroll-nav.scss';
import {Link} from "gatsby";


const ScrollNav = ( ) => {
  return (
      <ul className="scroll-nav">
        <li className="scroll-nav__item">
          <Link to="#monographs">Monographs</Link>
        </li>
        <li className="scroll-nav__item">
          <Link to="#edited_volumes_and_special_issues">Edited volumes & special issues</Link>
        </li>
        <li className="scroll-nav__item">
          <Link to="#peer_reviewed_articles">Peer reviewed articles</Link>
        </li>
        <li className="scroll-nav__item">
          <Link to="#book_chapters">Book chapters</Link>
        </li>
        <li className="scroll-nav__item">
          <Link to="#other_publications">Other publications</Link>
        </li>
      </ul>
  );
};

export default ScrollNav;

