import * as React from "react";
import PropTypes from "prop-types";
import './sub-page-header.scss'
import { Link } from "gatsby";
import Navigation from "./navigation";

const SubPageHeader = ({ title, intro, children }) => {
  return (
    <div className="sub-page-header grid">
      <div className="sub-page-header__container">
        {children}
      <Link to="/" className="sub-page-header__back-link">
        Tijo Salverda
      </Link>
      <h1 className="sub-page-header__title">
        {title}
      </h1>
      </div>
    </div>
  );
};

SubPageHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  intro: PropTypes.string,

};

export default SubPageHeader;

