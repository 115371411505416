import * as React from "react";
import './publication-item.scss'
import URL from "../elements/url";
import Date from "../elements/date";
import Category from "../elements/category";
import {Publication} from "../types";
import {FC} from "react";

interface PublicationItemProps extends Publication {
    showType: boolean;
}

const PublicationItem: FC<PublicationItemProps> = (props) => {
    function addHTTPS(url: string| undefined): string {
       if(!url) {
           return '';
       }

        if(url.startsWith("http")) {
            return url;
        }

        return `https://${url}`;
    }
  return (
    <div className="publication-item">
      <div className="publication-item__head-information">
        <div className="publication-item__date"><Date date={props.frontmatter.date} onlyYear={true}/></div>{ props.showType && <Category category={props.frontmatter.type}/> }
      </div>
      <div className="publication-item__text" dangerouslySetInnerHTML={{__html: props.html}}/>
      { props.frontmatter.url && <URL url={addHTTPS(props.frontmatter.url)} label={props.frontmatter.linkLabel} /> }
    </div>
  );
};

export default PublicationItem;

