import * as React from "react";
import './section-title.scss'
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Button from "./button";
import {ReactElement, ReactNode} from "react";
import classNames from "classnames";

interface SectionTitleProps {
    title: string;
    action?: string;
    actionPath?: string;
    children?: ReactNode;
    invertSide?: boolean
}

const SectionTitle = ({ title,children, invertSide}: SectionTitleProps) => {
  return (
    <div className={classNames("section-title", {"section-title--invert-side": invertSide})}>
      <div className="section-title__name">
        {title}
      </div>
      {children}
    </div>
  );
};

export default SectionTitle;

