import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";

export const TEMPLATE_KEY_THOUGHT_ARTICLE = "thought_article";
export const TEMPLATE_KEY_RESEARCH_ARTICLE ="research_article";
export const TEMPLATE_KEY_NEWS = "news";
export const TEMPLATE_KEY_PUBLICATIONS = "publication";
export const PUBLICATION_CATEGORY_CHAPTER = "Book Chapters";
export const PUBLICATION_CATEGORY_MONOGRAPH = "Monograph";
export const PUBLICATION_CATEGORY_OTHER = "Other Publications";
export const PUBLICATION_CATEGORY_EDITED_VOLUME_OR_SPECIAL_ISSUE = "Edited Volumes or Special Issues";
export const PUBLICATION_CATEGORY_PEER_REVIEWED_ARTICLES = "Peer Reviewed Articles";

type TemplateKey = typeof TEMPLATE_KEY_THOUGHT_ARTICLE | typeof TEMPLATE_KEY_RESEARCH_ARTICLE | typeof TEMPLATE_KEY_NEWS | typeof TEMPLATE_KEY_PUBLICATIONS;
export type PublicationType = typeof PUBLICATION_CATEGORY_CHAPTER | typeof PUBLICATION_CATEGORY_MONOGRAPH | typeof PUBLICATION_CATEGORY_OTHER;

export interface CMSFile {
    publicURL: string;
}

export interface MarkdownArticle {
    id: string;
    html: string;
    frontmatter:  ArticleFrontmatter;
}



export interface ArticleFrontmatter {
    title: string;
    body: string;
    image: FileNode;
    date: string;
    templateKey: TemplateKey;
    relatedResearch?: string[];
    relatedThoughts?: string[];
}

export interface Article {
    id: string;
    frontmatter: ArticleFrontmatter;
    excerpt: string;
    fields: {
        slug: string;
    };
}

interface NewsFrontmatter {
    text: string;
    date: string;
    url: string;
    linkLabel?: string;
    templateKey: TemplateKey;
}

interface PublicationFrontmatter extends NewsFrontmatter {
    type: PublicationType;
}

export interface News {
    id: string;
    fields: {
        slug: string;
    };
    frontmatter: NewsFrontmatter;
}

export interface Publication {
    html: string;
    frontmatter: PublicationFrontmatter;
}

export interface ArticleQueryResult {
    data: {
        articles: {
            nodes: Article[]
        }
    }
}

export interface PaginatedArticleQueryResult extends ArticleQueryResult {
    pageContext: {
        humanPageNumber: any;
        numberOfPages: number;
        pageNumber: number;
        previousPagePath: string;
        nextPagePath: string;
    }
}

export interface PublicationQueryResult {
    data: {
        publications: {
            nodes: Publication[]
        }
    }
}

export type ArticleOrNews = Article | News;

export interface ArticleOrNewsQueryResult {
    data: {
        articles: {
            nodes: (ArticleOrNews)[]
        }
    }
}

export interface AboutPageProps {
    html: string;
    frontmatter: {
        title: string;
        image: FileNode | undefined;
    }
}
export interface TransitionPageProps {
    html: string;
    frontmatter: {
        title: string;
        image: FileNode | undefined;
        relatedThoughts?: string[];
    }
}
